
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Field, ErrorMessage } from 'vee-validate';

@Options({
	name: 'TextInput',
	components: { Field, ErrorMessage },
	emits: ['update:modelValue'],
})
export default class TextInput extends Vue {
	@Prop({ default: 'text' }) type!: 'number' | 'text' | 'email';
	@Prop() name!: string;
	@Prop() modelValue!: string | number;
	@Prop() placeholder!: string | number;
	@Prop({ default: 'This field is invalid' }) error!: string;
	@Prop({ default: false }) required!: boolean;
	@Prop({ default: [] }) validators!: ((v: any) => boolean)[];

	invalid = false;

	mounted() {
		this.invalid = false;
	}
	validateField(val: unknown): boolean {
		const valid = this.validators.every((v) => v(val));
		this.invalid = !valid;
		return valid;
	}
}
