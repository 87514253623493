import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-row flex-gap-8 flex-align-start custom-field-terms" }
const _hoisted_2 = { class: "flex-col flex-gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox = _resolveComponent("checkbox")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.name,
    rules: _ctx.validate
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange(!_ctx.modelValue))),
          class: "clickable"
        }, [
          _createVNode(_component_checkbox, { checked: _ctx.modelValue }, null, 8, ["checked"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.fieldName) + _toDisplayString(_ctx.isRequired ? '*' : ''), 1),
          (_ctx.policyTerms)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "clickable highlight-text hvr-underline",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onOpenPolicy && _ctx.onOpenPolicy(...args)))
              }, "Read More"))
            : _createCommentVNode("", true)
        ])
      ]),
      (!_ctx.isValid)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            name: _ctx.name,
            class: "si-field-invalid",
            as: 'span'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.error), 1)
            ]),
            _: 1
          }, 8, ["name"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "rules"]))
}