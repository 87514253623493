import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "width-100" }
const _hoisted_2 = { class: "flex-col gap-4" }
const _hoisted_3 = { class: "ib-title" }
const _hoisted_4 = {
  key: 0,
  class: "fs-s color-secondary"
}
const _hoisted_5 = {
  key: 0,
  class: "package-activity-fields flex-col gap-inherit"
}
const _hoisted_6 = { class: "fs-l fw-medium mb-4" }
const _hoisted_7 = { class: "fs-s color-secondary" }
const _hoisted_8 = {
  key: 1,
  class: "activity-custom-fields flex-col gap-inherit"
}
const _hoisted_9 = {
  key: 2,
  class: "rate-custom-fields gap-inherit"
}
const _hoisted_10 = { class: "flex-col gap-6 mb-20 section-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_field = _resolveComponent("custom-field")!
  const _component_progress_button = _resolveComponent("progress-button")!
  const _component_Form = _resolveComponent("Form")!
  const _component_info_box = _resolveComponent("info-box")!

  return (_openBlock(), _createBlock(_component_info_box, {
    isActive: _ctx.isActive,
    onOnEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onEdit'))),
    canEdit: _ctx.sectionComplete || _ctx.sectionSkipped
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.Name), 1),
          (!_ctx.item?.OrderPackageKey && _ctx.customFieldActivities.length > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.appointmentString(_ctx.item.Activities[0])), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        id: 'infoForm',
        class: "flex-col gap-24 ib-form",
        onSubmit: _ctx.onSubmit,
        ref: "formRef"
      }, {
        default: _withCtx(({ validate }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFieldActivities, (activity, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "flex-col gap-inherit"
            }, [
              (_ctx.item?.OrderPackageKey)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(activity.Name), 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.appointmentString(activity)), 1)
                  ]))
                : _createCommentVNode("", true),
              (activity.CustomFields.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activity.CustomFields, (acf, j) => {
                      return (_openBlock(), _createBlock(_component_custom_field, {
                        key: j,
                        fieldKey: `${acf.OrderClientLocationCustomFieldParticipantGUID}-${acf.ClientLocationCustomFieldKey}-${j}`,
                        modelValue: activity.CustomFields[j],
                        "onUpdate:modelValue": ($event: any) => ((activity.CustomFields[j]) = $event)
                      }, null, 8, ["fieldKey", "modelValue", "onUpdate:modelValue"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.customFieldParticipants(activity))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFieldParticipants(activity), (cfp, k) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "si-border br-8 p-12 flex-col gap-12",
                        key: k
                      }, [
                        _createTextVNode(_toDisplayString(cfp.Heading) + " ", 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cfp.CustomFields, (cfpcf, l) => {
                          return (_openBlock(), _createBlock(_component_custom_field, {
                            key: l,
                            fieldKey: `${cfp.OrderClientLocationCustomFieldParticipantGUID}-${cfpcf.ClientLocationCustomFieldKey}-${k}`,
                            modelValue: cfp.CustomFields[l],
                            "onUpdate:modelValue": ($event: any) => ((cfp.CustomFields[l]) = $event)
                          }, null, 8, ["fieldKey", "modelValue", "onUpdate:modelValue"]))
                        }), 128))
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          _createVNode(_component_progress_button, {
            showLoader: _ctx.loading,
            onClick: validate
          }, {
            default: _withCtx(() => [
              _createTextVNode("Next")
            ]),
            _: 2
          }, 1032, ["showLoader", "onClick"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    summary: _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activityResponses, (cf, i) => {
          return (_openBlock(), _createElementBlock("div", { key: i }, [
            _createElementVNode("span", null, _toDisplayString(cf.name), 1),
            _createElementVNode("em", null, _toDisplayString(cf.response), 1)
          ]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rateResponses, (cf, i) => {
          return (_openBlock(), _createElementBlock("div", { key: i }, [
            _createElementVNode("span", null, _toDisplayString(cf.heading), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cf.responses, (rcf, j) => {
              return (_openBlock(), _createElementBlock("div", { key: j }, [
                _createElementVNode("span", null, _toDisplayString(rcf.name), 1),
                _createElementVNode("em", null, _toDisplayString(rcf.response), 1)
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["isActive", "canEdit"]))
}