
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
// import { clientModule } from '@/modules/client/client.vuex-module';
// import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
// import { getCustomFieldResponseString } from '@/common/utilities/customFieldResponse.utility';
import InfoBox from './info-box.component.vue';
import TextInput from '@/common/components/form-ui/text-input.component.vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import SelectInput from '@/common/components/form-ui/select-input.component.vue';
import CustomField from '@/common/components/form-ui/custom-field.component.vue';
import RequirementsToParticipate from '@/common/components/form-ui/requirements-to-participate.component.vue';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { ClientLocationCustomField } from '@/common/models/ClientLocationCustomField.model';
import { EntityHierarchy } from '@/common/models/EntityHierarchy.model';
import { ClientLocationCustomFieldParticipant } from '@/common/models/ClientLocationCustomFieldParticipant.model';
import { format, parseISO } from 'date-fns';
import { Item } from '@/common/models/Item.model';
import ProgressButton from '@/common/components/progress-button.component.vue';

export function formatCustomFieldResponse(field: ClientLocationCustomField): string {
	switch (field.ResponseTypeKey) {
		case 1:
			if (!field.Response_Boolean) return '';
			return field.CustomFieldResponses.find((resp) => resp.Response_Boolean == field.Response_Boolean)?.ResponseLabel ?? 'N/A';
		case 2:
		case 4:
			if (!field.Response_Text) return '';
			return field.Response_Text;
		case 3: {
			if (!field.Selected_ClientLocationCustomFieldResponseKeys.at(0)) return '';
			const response = field.CustomFieldResponses.find((r) => r.ClientLocationCustomFieldResponseKey == field.Selected_ClientLocationCustomFieldResponseKeys[0])?.ResponseLabel;
			if (response) return response;
			else return '';
		}
	}
	return '';
}

@Options({
	name: 'ItemInfoBox',
	components: { InfoBox, TextInput, SelectInput, Form, CustomField, RequirementsToParticipate, Field, ErrorMessage, ProgressButton },
	emits: ['onNext', 'onEdit'],
})
export default class ItemInfoBox extends Vue {
	@Prop({ default: true }) isActive!: boolean;
	@Prop() item!: Item;

	loading = false;
	sectionComplete = false;
	sectionSkipped = false;
	customFieldParticipants(activity: EntityHierarchy): ClientLocationCustomFieldParticipant[] {
		return activity.SelectedTimes[0]?.Rates.flatMap((r) => r.CustomFieldParticipants) ?? [];
	}

	get activeOrder() {
		return orderModule._activeOrder!;
	}

	get customFields(): ClientLocationCustomField[] {
		return this.activeOrder.customFields;
	}
	get customFieldActivities() {
		return this.item?.Activities.filter((a) => a.CustomFields.length || a.Rates.some((r) => r.CustomFieldParticipants.length)) ?? [];
	}

	get activityResponses() {
		const fields: { label?: string; name: string; response: string | number | boolean }[] = [];

		this.customFieldActivities.forEach((activity) => {
			activity.CustomFields.forEach((acf) => {
				const response = formatCustomFieldResponse(acf);
				if (response) fields.push({ name: acf.CustomFieldName, response });
			});
		});
		return fields;
	}

	get rateResponses() {
		const fields: { heading: string; responses: { name: string; response: string | number | boolean }[] }[] = [];

		this.customFieldActivities.forEach((activity) => {
			this.customFieldParticipants(activity).forEach((cfp) => {
				const participant: (typeof fields)[0] = { heading: cfp.Heading, responses: [] };
				cfp.CustomFields.forEach((cf) => {
					const response = formatCustomFieldResponse(cf);
					if (response) participant.responses.push({ name: cf.CustomFieldName, response: response });
				});
				if (participant.responses.length) fields.push(participant);
			});
		});

		return fields;
	}
	appointmentString(activity: EntityHierarchy) {
		const selectedTime = activity.SelectedTimes[0];
		if (!selectedTime) return '';
		const dateString = format(parseISO(selectedTime.AppointmentDate), 'EE, MMMM d');
		switch (activity.AppointmentTypeKey) {
			case 1:
				return `${dateString} @ ${selectedTime.DisplayName}`;
			case 2:
				return `${dateString}`;
			case 3:
				return '';
		}
	}
	get canSkipSection() {
		return this.customFieldActivities.filter((a) => a.CustomFields.some((cf) => cf.IsRequired) || a.Rates.some((r) => r.CustomFieldParticipants.some((cfp) => cfp.CustomFields.some((cfpcf) => cfpcf.IsRequired)))).length == 0;
	}
	onSubmit() {
		this.loading = true;
		this.activeOrder
			.updateOrder()
			.then((res) => {
				if (res) {
					this.$emit('onNext');
				}
			})
			.finally(() => {
				this.loading = false;
			});
	}
}
