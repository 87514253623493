
import SelectInput from '@/common/components/form-ui/select-input.component.vue';
import TextInput from '@/common/components/form-ui/text-input.component.vue';
import TextareaInput from '@/common/components/form-ui/textarea-input.component.vue';
import { ClientLocationCustomField } from '@/common/models/ClientLocationCustomField.model';
import { isRequired } from '@/common/utilities/Validators.utility';
import { getCustomFieldResponse, setCustomFieldResponse } from '@/common/utilities/customFieldResponse.utility';
import { ErrorMessage, Field } from 'vee-validate';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PolicyAgreement from './policy-agreement.component.vue';
import RadioButtonGroup from './radio-button-group.component.vue';

@Options({
	name: 'CustomField',
	components: { TextInput, SelectInput, Field, ErrorMessage, RadioButtonGroup, TextareaInput, PolicyAgreement },
	emits: ['update:modelValue'],
})
export default class CustomField extends Vue {
	@Prop() modelValue!: ClientLocationCustomField;
	@Prop({ default: '' }) fieldKey!: string;

	get validationRules() {
		return this.modelValue.IsRequired ? [isRequired] : [];
	}

	get fieldResponse(): string | number | boolean | null {
		return getCustomFieldResponse(this.modelValue);
	}

	set fieldResponse(res: string | number | boolean | null) {
		setCustomFieldResponse(this.modelValue, res);
		this.$emit('update:modelValue', this.modelValue); // emit changes to save custom field
	}
	get key() {
		return this.fieldKey ? this.fieldKey : this.modelValue.ClientLocationCustomFieldKey.toString();
	}
	get selectOptions() {
		return this.modelValue.CustomFieldResponses.map((r) => {
			return {
				label: r.ResponseLabel,
				value: r.ClientLocationCustomFieldResponseKey,
			};
		});
	}

	get boolOptions() {
		return [
			{
				label: this.modelValue.CustomFieldResponses[0].ResponseLabel,
				value: true,
			},
			{
				label: this.modelValue.CustomFieldResponses[1].ResponseLabel,
				value: false,
			},
		];
	}
}
