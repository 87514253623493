import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "si-input-field" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_policy_agreement = _resolveComponent("policy-agreement")!
  const _component_textarea_input = _resolveComponent("textarea-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modelValue.ResponseTypeKey != 1)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.modelValue.CustomFieldName) + _toDisplayString(_ctx.modelValue.IsRequired ? '*' : ''), 1))
      : _createCommentVNode("", true),
    (_ctx.modelValue.ResponseTypeKey == 1)
      ? (_openBlock(), _createBlock(_component_policy_agreement, {
          key: 1,
          name: _ctx.fieldKey,
          fieldName: _ctx.modelValue.CustomFieldName,
          isRequired: _ctx.modelValue.IsRequired,
          modelValue: _ctx.fieldResponse,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldResponse) = $event)),
          policyTerms: _ctx.modelValue.CustomFieldDesc,
          options: _ctx.boolOptions
        }, null, 8, ["name", "fieldName", "isRequired", "modelValue", "policyTerms", "options"]))
      : (_ctx.modelValue.ResponseTypeKey == 2)
        ? (_openBlock(), _createBlock(_component_textarea_input, {
            key: 2,
            name: _ctx.fieldKey,
            placeholder: _ctx.modelValue.CustomFieldDesc,
            modelValue: _ctx.fieldResponse,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fieldResponse) = $event)),
            validators: _ctx.validationRules,
            error: _ctx.modelValue.IsRequired ? 'This field is required' : ''
          }, null, 8, ["name", "placeholder", "modelValue", "validators", "error"]))
        : (_ctx.modelValue.ResponseTypeKey == 4)
          ? (_openBlock(), _createBlock(_component_text_input, {
              key: 3,
              name: _ctx.fieldKey,
              placeholder: _ctx.modelValue.CustomFieldDesc,
              modelValue: _ctx.fieldResponse,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fieldResponse) = $event)),
              validators: _ctx.validationRules,
              error: _ctx.modelValue.IsRequired ? 'This field is required' : ''
            }, null, 8, ["name", "placeholder", "modelValue", "validators", "error"]))
          : (_ctx.modelValue.ResponseTypeKey == 3)
            ? (_openBlock(), _createBlock(_component_select_input, {
                key: 4,
                name: _ctx.fieldKey,
                options: _ctx.selectOptions,
                modelValue: _ctx.fieldResponse,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fieldResponse) = $event)),
                validators: _ctx.validationRules,
                error: _ctx.modelValue.IsRequired ? 'This field is required' : ''
              }, null, 8, ["name", "options", "modelValue", "validators", "error"]))
            : _createCommentVNode("", true)
  ]))
}